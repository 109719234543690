@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local('Roboto'), url(./Roboto/Roboto-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: local('Roboto'), url(./Roboto/Roboto-ThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local('Roboto'), url(./Roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local('Roboto'), url(./Roboto/Roboto-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url(./Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local('Roboto'), url(./Roboto/Roboto-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url(./Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: local('Roboto'), url(./Roboto/Roboto-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local('Roboto'), url(./Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local('Roboto'), url(./Roboto/Roboto-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local('Roboto'), url(./Roboto/Roboto-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: local('Roboto'), url(./Roboto/Roboto-BlackItalic.ttf) format("truetype");
}